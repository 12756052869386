import Header from "../components/Header";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ModalComponent from "../components/Modal";

let imagesArr = [
  "/images/whoops/1.jpg",
  "/images/whoops/2.jpg",
  "/images/whoops/3.jpg",
  "/images/whoops/4.jpg",
  "/images/whoops/5.jpg",
  "/images/whoops/6.jpg",
  "/images/whoops/7.jpg",
  "/images/whoops/8.jpg",
  "/images/whoops/9.jpg",
  "/images/whoops/10.jpg",
  "/images/whoops/11.jpg",
  "/images/whoops/12.jpg",
  "/images/whoops/13.jpg",
  "/images/whoops/14.jpg",
  "/images/whoops/15.jpg",
];

const Whoops = () => {
  let innerWidth = window.innerWidth;
  const { t } = useTranslation();
  const [isImage, setImage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState(imagesArr);
  const language = localStorage.getItem("language");

  const showImage = (tab) => {
    setImage(tab);
  };
  const onClick = (imgRb) => {
    const newImageList = [
      ...imagesArr.slice(imgRb),
      ...imagesArr.slice(0, imgRb),
    ];
    setImages(newImageList);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <div className="film-details" id="film-details">
      <Header />
      <div className="film-details-all-page">
        <div className="film-details-img">
          <img
            src={
              innerWidth > 600
                ? language === "rs"
                  ? "/images/whoops/glavna.jpg"
                  : "/images/whoops/glavna_eng.jpg"
                : language === "rs"
                ? "/images/whoops/glavna_phone.jpg"
                : "/images/whoops/glavna_eng_phone.jpg"
            }
            alt="whoops"
            id="tracak-details-image"
            className="details-img-tracak"
          />
        </div>
        <div className="film-details-menu">
          <button onClick={() => showImage(false)}>
            <div className="film-details-tab" id={!isImage ? "selected" : ""}>
              Trailer
            </div>
          </button>
          <button onClick={() => showImage(true)}>
            <div className="film-details-tab" id={isImage ? "selected" : ""}>
              {t("galerija")}
            </div>
          </button>
        </div>

        {!isImage ? (
          <div className="film-trailer">
            <div className="film-details-video">
              <video className="film-video" controls autoPlay muted loop>
                <source src="/images/reklama.m4v" type="video/mp4" />
              </video>
            </div>
          </div>
        ) : (
          <div className="film-trailer">
            <div className="row-img">
              <div className="film-img">
                <img
                  src="/images/whoops/1.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(0)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/2.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(1)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/3.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(2)}
                />
              </div>
            </div>

            <div className="row-img">
              <div className="film-img">
                <img
                  src="/images/whoops/4.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(3)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/5.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(4)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/6.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(5)}
                />
              </div>
            </div>

            <div className="row-img">
              <div className="film-img">
                <img
                  src="/images/whoops/7.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(6)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/8.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(7)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/9.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(8)}
                />
              </div>
            </div>

            <div className="row-img">
              <div className="film-img">
                <img
                  src="/images/whoops/10.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(9)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/11.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(10)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/12.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(11)}
                />
              </div>
            </div>

            <div className="row-img">
              <div className="film-img">
                <img
                  src="/images/whoops/13.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(12)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/14.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(13)}
                />
              </div>
              <div className="film-img">
                <img
                  src="/images/whoops/15.jpg"
                  alt="ison tracak"
                  onClick={() => onClick(14)}
                />
              </div>
            </div>
          </div>
        )}
        <ModalComponent
          show={showModal}
          handleClose={handleClose}
          images={images}
        />
      </div>

      <Footer />
    </div>
  );
};

export default Whoops;
